.investor-container {
    text-align: center;
    max-width: 70vw;
    margin: auto;
  }
  
  .investor-title {
    /* margin-top: 4rem; */
    font-size: 36px;
    text-transform: uppercase;
    margin: 0;
  }
  
  .investor-subtitle {
    font-size: 18px;
    margin: 20px 0;
  }
  
  .investor-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 40px 0;
  }
  
  .investor-section.normal,
  .investor-section.flipped {
    flex-direction: row;
    justify-content: space-between;
  }
  
  .investor-section.flipped {
    flex-direction: row-reverse;
  }
  
  .investor-number {
    font-size: 80px;
    color: darkgoldenrod;
    position: relative;
    margin-bottom: -40px;
  }
  
  .investor-content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  
  .investor-section.flipped .investor-content {
    flex-direction: row-reverse;
  }
  
  .investor-text {
    flex: 1;
    padding: 20px;
  }
  
  .investor-label {
    text-transform: uppercase;
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .investor-description {
    font-size: 16px;
  }
  
  .investor-image {
    max-height: 200px;
    width: auto;
    flex-shrink: 0;
    margin: 20px;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .investor-container {
      max-width: 95vw; /* Give a bit of padding on the sides */
    }
  
    .investor-section {
      flex-direction: column;
    }
  
    .investor-section .investor-content,
    .investor-section.flipped .investor-content {
      /* Reset flex-direction for mobile */
      flex-direction: column;
    }
  
    .investor-number {
      font-size: 60px; /* Adjust number size for mobile */
      margin-bottom: 20px; /* Adjust number position for mobile */
    }
  
    .investor-text {
      order: 2; /* Ensure text comes after the image */
      padding: 10px; /* Reduce padding on mobile */
      text-align: left; /* Align text to the left */
    }
  
    .investor-section.flipped .investor-text {
      order: 1; /* For flipped, text should come first */
    }
  
    .investor-image {
      max-height: 200px; /* Adjust image size for mobile */
      order: 1; /* Image comes first */
      width: 100%; /* Full width images on mobile */
      margin: 0 0 20px; /* Adjust margin for mobile */
    }
  
    .investor-section.flipped .investor-image {
      order: 2; /* For flipped, image should come second */
    }
  }
.property-management {
  font-family: 'Montserrat', sans-serif;
  color: #333;
  max-width: 95vw;
  margin: 0 auto;
  padding: 1.25rem;
}

.property-header-background {
  background-size: cover;
  background-position: center;
  padding: 2rem;
  max-width: 85vw; /* Slightly larger than the header */
  margin: 0 auto;
  margin-bottom: 2rem;
}

.property-header {
  background: #eee6d0;
  padding: 3rem;
  max-width: 45vw;
  margin: 0 auto; /* Center the header */
  text-align: center; /* Center the text */
}

  .property-header h1 {
    color: #000; /* Black color for the title */
    font-size: 2.5rem;
    font-weight: 200;
    margin-bottom: -2rem;
    text-align: center;
  text-transform: uppercase;

  }
  
  .property-description {
    color: #000; /* Black color for the description */
    font-size: 1.5rem;
    margin-bottom: 2rem;
    padding: 1.2rem;
    text-align: center;
  }
  
  .included-section {
    background-color: #e0d7bf; /* Slightly darker shade of beige */
    padding: 2rem;
    max-width: 60vw;
    margin: 0 auto; /* Center the header */
    margin-bottom: 2rem;
    /* border-radius: 0.5rem; */
  }
  
  .included-section h2 {
    color: #000;
    font-size: 3rem;
    font-weight: 200;
    margin-bottom: 1rem;
    text-align: center;
  }
  .included-section p {
    padding: 2.5rem;
    font-size: 1.5rem;
    text-align: center;
    font-style: normal;
  }
  
  .steps {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
    gap: 1.5rem;
    margin-top: 2rem;
    margin: 4rem;
  }
  .step-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #e4e2db;
    border: 1px solid #E1E1E1;
    box-shadow: 0 0.625rem 1.25rem rgba(0, 0, 0, 0.08);
    overflow: hidden;
    transition: box-shadow 0.3s ease;
  }
  
  .step-card h3 {
    color: #1d1c1c;
    font-size: 1.25rem;
    font-weight: 500;
    text-align: center;
    padding: 1rem 1.5rem;
  }
  
  .step-line {
    height: 0.125rem; /* Height of the line */
    background-color: #000; /* Color of the line */
    width: 80%; /* Width of the line, you can adjust as needed */
    margin-bottom: 1rem; /* Space below the line */
  }
  .step-card img {
    width: 100%;
    height: 200px; /* Set a fixed height for the image */
    object-fit: cover; /* Maintain the aspect ratio without stretching */
    border-bottom: 1px solid #E1E1E1;
  }
  .step-card p {
    color: #141212;
    font-size: 1.25rem;
    padding: 0.5rem 1.5rem 1.5rem;
    text-align: center;
    font-weight: 400;
  }
  
  @media (max-width: 48em) { /* 768px */

    body, html {
      overflow-x: hidden;
      max-width: 100%;
    }
    .property-management h1 {
      font-size: 1.75rem;
    }
    .property-description {
      font-size: 1rem;
      margin-top: 5rem;
      padding: .25rem;
    }
    .included-section h2 {
      color: #000;
      font-size: 1.75rem;
      font-weight: 200;
      margin-bottom: 1rem;
      text-align: center;
    }
    .included-section p {
      padding: .5rem;
      font-size: 1.15rem;
      text-align: center;
      font-style: normal;
    }
    
    .steps {
      grid-template-columns: 1fr;
    }
    .step-card {
      max-width: 95vw;
      padding: .25rem
    }
    .step-card p {
      color: #141212;
      font-size: 1rem;
      padding: 0.5rem;
      text-align: center;
      font-weight: 250;
    }
    .step-card h3 {
      font-size: 1.25rem;
    }
  
    .property-header {
      max-width: 90vw;
    }
  
    .step-line {
      top: 4rem; /* Adjust the line position on smaller screens */
    }
  }
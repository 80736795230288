.contact-container {
    display: flex;
    justify-content: space-between;
    background-size: cover;
    color: white;
    position: relative;
    padding: 3.125rem;
    font-family: 'Montserrat', sans-serif;
    max-width: 1200px; /* Max width for the container */
    margin: auto; /* Center the container */
  }
  
  .contact-container:before {
    content: '';
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
  }
  
  .contact-header {
    font-weight: 600;
  }
  
  .contact-details, .submit-message {
    position: relative;
    z-index: 2;
    width: calc(50% - 2rem);
    margin: 1.25rem;
  }
  
  .contact-details h2, .submit-message h2 {
    margin-bottom: 1.25rem;
  }
  
  .contact-info i, .social-links a i {
    color: white;
    margin-right: 0.625rem;
  }
  
  .social-links a {
    display: inline-block;
    margin: 0 0.3125rem;
    padding: 0.625rem;
    background: #666;
    border-radius: 50%;
    border: 2px solid white;
  }
  
  .info-item {
    display: flex;
    align-items: center;
    margin-bottom: 0.625rem;
  }
  
  .info-icon-text {
    display: flex;
    align-items: center;
  }
  
  .info-text {
    margin-left: 0.625rem;
  }
  
  .additional-info {
    margin-top: 0.625rem;
    font-size: 0.9rem;
  }
  .vertical-line {
    background-color: white;
    width: 0.15rem;
    height: 80%;
    
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }
  
  /* input, textarea {
    background: none;
    border: none;
    border-bottom: 1px solid white;
    margin-bottom: 1.25rem;
    padding: 0.625rem 0.3125rem;
    color: white;
    font-size: 1rem;
    outline: none;
  }
  
  input::placeholder, textarea::placeholder {
    color: #ccc;
  }
  
  input[type="submit"] {
    background: white;
    color: black;
    border: none;
    padding: 0.9375rem 1.875rem;
    text-transform: uppercase;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
  }
  
  input[type="submit"]:hover {
    background: #333;
    color: white;
  }
   */
   .submit-message-container {
    position: relative;
    z-index: 2;
    width: calc(50% - 2rem);
    margin: 1.25rem;
  }
  
  .submit-message form {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  .submit-message input,
  .submit-message textarea {
    width: 100%; /* Full width */
    margin-bottom: 0.625rem; /* Consistent margin */
    background: none;
    border: none;
    border-bottom: 1px solid white;
    margin-bottom: 1.25rem;
    padding: 0.625rem 0.3125rem;
    color: white;
    font-size: 1rem;
    outline: none;
  }
  
  .submit-message input[type="submit"] {
    margin-top: 1rem;
    width: auto; /* Auto width */
    background: white;
    color: black;
    border: none;
    padding: 0.9375rem 1.875rem;
    text-transform: uppercase;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
  }

  .submit-message input[type="submit"]:hover {
    background: #333;
    color: white;
  }
  
  .telephone-link {
    color: white; /* or any color your design uses */
    text-decoration: none; /* no underline */
  }
  
  /* You may also want to consider styling for hover, active, visited states if needed */
  .telephone-link:hover {
    text-decoration: underline; /* as an example */
  }


  @media (max-width: 768px) {
    .contact-container {
      flex-direction: column;
      align-items: center;
      padding: 1.5625rem;
    }
  
    .contact-details, 
    .submit-message-container {
      width: 100%;
    }
  
    .submit-message input,
    .submit-message textarea,
    .submit-message input[type="submit"] {
      width: 100%; /* Full width on mobile */
    }
  
    .vertical-line {
      display: none;
    }
  }
/* src/components/style/Homepage.css */
.homepage {
    /* General styles for the homepage */
    /* font-family: Montserrat; */
    font-family: "Montserrat", sans-serif;
  

  }
  
  .video-section {
    position: relative;
    max-height: 80vh;
    overflow: hidden;

  }
  
  .video-section video {
    width: 100%;
    height: auto;
    display: block;
  }
  
  .video-section h2 {
    position: absolute;
    bottom: 50%;
    left: 50%;
    transform: translateX(-50%);
    color: white;
    font-family: "Montserrat", sans-serif;
    font-size: 2rem;
    font-weight: 300;
    text-align: center;
    text-transform: uppercase;
    /* Additional styling for your caption */
  }
  
  .getting-to-know-us {
    display: flex;
    background-color: #f7f5f2; /* Beige background */
    /* padding: 0.25rem; */
    align-items: center; /* Align items vertically */
    justify-content: center; /* Center the content horizontally */
    gap: 2rem; /* Add some space between the text and the image */
  }
  
  .getting-to-know-us .content {
    flex: 1; /* Take up equal amount of space as the image */
    text-align: center; /* Center the text */
    padding: 2rem;
    max-width: 50%; /* Limit the width to half of the container */
  }
  
  .getting-to-know-us .section-title {
    color: black; /* Default color for the title */
    font-weight: lighter;
    font-size: 3rem;
    padding: 2rem;
  }
  
  .getting-to-know-us .description {
    color: rgb(150, 139, 77); /* Apply this color to both descriptions */
    margin-left: .25rem;
  }
  .getting-to-know-us .description2 {
    color: rgb(0, 0, 0); /* Apply this color to both descriptions */
    margin-left: .25rem;

  }
  
  .getting-to-know-us img {
    flex: 1; /* Take up equal amount of space as the text content */
    max-width: 50%; /* Limit the width to half of the container */
    object-fit: cover; /* Cover the area without losing aspect ratio */
  }
  
  /* Modern button styling */
  .contact-button {
    background-color: #000; /* Black background */
    color: #fff; /* White text */
    border: 2px solid #fff; /* White border */
    padding: 3rem 6rem;
    font-size: 2rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 200;
    transition: all 0.3s ease;
    margin-top: 1rem;
    cursor: pointer;
    outline: none;
  }
  
  .contact-button:hover {
    background-color: #beac42; /* Gold background */
    color: #000; /* Black text */
  }

/* Assuming you have a <div className="help-section-container"> wrapping the .help-section */
.help-section-container {
  background-color: #fff8ed; /* Beige background */
  text-align: center; /* Center the title */
  padding: 4rem ;
   /* Top and bottom padding, 1rem side padding if not full width */
}

.help-section {
  max-width: 1200px; /* or whatever max width you prefer */
  margin: 0 auto; /* Center the grid in the beige container */
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  padding: 2rem;
  /* margin-left: 4rem; */
}

.help-section-title {
  font-size: 3.75rem; /* Size of the title */
  font-weight: 300; /* Lighter font-weight */
  margin-bottom: 2rem; /* Space below the title */
  text-transform: uppercase; /* Uppercase letters */
  color: #b9ab8d; /* Title color */
}
.help-item {
  /* Your existing styles */
  
  position: relative; /* Needed for absolute positioning of the banner */
}

.help-item-content {
  padding: 1rem;
}

.help-item-image img {
  width: 100%;
  height: auto;
  display: block;
}

/* Gold banner at the bottom */
.help-item-banner {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 80%;
  background-color: #b9ab8d;
  padding: .25rem;
  clip-path: polygon(0% 0%, 100% 0%, 90% 100%, 0% 100%);
}

.help-item-banner h3 {
  margin: 0;
 
  text-transform: uppercase;
  font-weight: lighter; /* If you have a font-weight lighter than normal */
  color: rgb(255, 255, 255);
  padding: .25rem;
  text-align: left;
}

.communities-section {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1rem;
  padding: 2rem;
}

.community-item-link {
  display: block; /* Make Link behave like a block element */
  margin: 1rem; /* Adjust the margin as needed */
  color: inherit; /* Inherits the text color */
  text-decoration: none; /* Removes the underline from the link */
}

.community-item {
  position: relative;
  cursor: pointer;
  color: white;
  overflow: hidden; /* Ensure the image zoom doesn't break the layout */
}

.community-item h3 {
  position: absolute;
  width: 100%;
  text-align: center;
  bottom: 50%;
  left: 0;
  transform: translateY(50%);
  margin: 0;
  padding: 0.5rem 0;
  font-size: 1.5rem;
  font-weight: 400;
  z-index: 2;
  cursor:grab;
  transition: transform 0.3s ease-in-out;
}

.community-item img {
  width: 100%;
  height: 100%; /* Make the image cover the item's dimensions */
  object-fit: cover; /* This will cover the area, cropping the image as needed */
  transition: transform 0.3s ease-in-out; /* Smooth transition for scaling */
}

.community-item::after {
  content: '';
  background: linear-gradient(to bottom, rgba(0,0,0,0.3), rgba(0,0,0,0.3));
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.community-item:hover img {
  /* cursor:crosshair; */
  background: linear-gradient(to bottom, rgba(0,0,0,0.3), rgba(0,0,0,0.3));
  transform: scale(1.05); /* Slightly enlarge the image */
}


.community-item:hover::after {
  opacity: 1; /* Make the overlay visible on hover */
  background: linear-gradient(to bottom, rgba(0,0,0,0.5), rgba(0,0,0,0.5)); /* Darken the overlay a bit if needed */
}
  /* Responsive styles */
  @media (max-width: 768px) {
    .video-section {
      position: relative;
      max-height: 80vh;
      overflow: hidden;
  
    }
    .mobile-video-fallback {
      display: block; /* Show the fallback image */
      width: 100%;
      height: auto; /* Adjust the height as needed, or use 100% if it should be full-screen */
      object-fit: cover;
    }
    .video-section video {
      display: none; /* Hide the video */
    }
    .video-section h2 {
      position: absolute;
      bottom: 40%;
      left: 50%;
      /* transform: translateX(-50%); */
      color: white;
      font-family: "Montserrat", sans-serif;
      font-size: 1.25rem;
      font-weight: 400;
      text-align: center;
      text-transform: uppercase;
      /* Additional styling for your caption */
    }
    .getting-to-know-us,
    .help-section,
    .communities-section {
      flex-direction: column;
    }
  
    .getting-to-know-us .content,
    .getting-to-know-us img,
    .help-item,
    .community-item {
      width: 100%;
      margin-bottom: 1rem;
    }
    .getting-to-know-us .content,
  .getting-to-know-us img {
    max-width: 100%; /* Each takes full width on smaller screens */
    padding: 20px; /* Add padding for smaller screens */
  }
  .help-section {
    grid-template-columns: 1fr; /* Stack items on smaller screens */
    padding: 0 1rem; /* Smaller horizontal padding on mobile */
  }
  .help-section-title {
    font-size: 2.5rem;
    margin-bottom: 3rem;
  }
  .help-item-image img {
    width: 100%; /* Full width images on smaller screens */
  }
  .communities-section {
    grid-template-columns: 1fr; /* Stack them on top of each other */
  }
  .community-item h3 {
    font-size: 1rem; /* Adjust font size for smaller screens */
  }

  }
  
/* src/components/styles/Navbar.css */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #faf8f8;
  padding: 0.5rem 2rem;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.logo-container .logo {
  max-width: 150px;
  height: auto;
}

.menu-icon {
  font-size: 2rem;
  cursor: pointer;
  display: none; /* This should not display on desktop */
}

.nav-items-desktop,
.nav-items-mobile {
  list-style: none;
  padding: 0;
  margin: 0;
  align-items: center;
}

.nav-items-desktop {
  display: flex;
}

.nav-items-desktop a {
  color: #000000;
  text-decoration: none;
  text-align: right;
  padding: 0.5rem 1.5rem;
  transition: color 0.3s ease;
}

.nav-items-desktop a:hover,
.nav-items-desktop a.active {
  color: rgb(199, 178, 61);
}

.search-icon {
  color: #0a0a0a;
  font-size: 1.5rem;
  cursor: pointer;
}

.dropdown {
  position: relative; /* Required for absolute positioning of dropdown-content */
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #faf8f8;
  min-width: 160px; /* Adjust width as necessary */
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1001; /* Ensure it's above other content */
}

.dropdown-content a {
  color: black;
  padding: 12px 16px; /* Adjust as necessary */
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #ddd; /* Adjust hover color as necessary */
}

.dropdown:hover .dropdown-content {
  display: block; /* Show dropdown on hover */
  text-align: center;
  padding: 1rem;
}




/* Mobile Styles */
@media (max-width: 768px) {
  .nav-items-desktop {
    display: none; /* Hide desktop nav items on mobile */
  }

  .menu-icon {
    display: block; /* Show the menu icon on mobile */
  }

  .search-icon {
    display: none; /* Hide the search icon on mobile */
  }

  .nav-items-mobile {
    display: none; /* Hidden by default, shown when menu is open */
    flex-direction: column;
    justify-content: start;
    background-color: #000;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: 75%; /* You can adjust the width as needed */
    padding-top: 3rem; /* Adjust for spacing */
    z-index: 1500;
  }

  .nav-items-mobile a {
    color: #fff;
    text-decoration: none;
    padding: 1rem;
    text-align: center;
    border-bottom: 1px solid #333; /* Keep the separation between items */
    width: calc(100% - 2rem); /* Subtract the total horizontal padding from the width */
    margin-left: 1rem; /* Add margin to center the link */
    box-sizing: border-box; /* Ensure padding and border are included in the width */
  }

  .nav-items-mobile a:hover,
  .nav-items-mobile a.active {
    color: rgb(150, 139, 77);
    background-color: rgba(199, 178, 61, 0.1); /* Subtle hover effect */
  }

  .nav-items-mobile .dropdown-toggle {
    color: #fff;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }

  .nav-items-mobile .dropdown-content {
    display: none;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: #000; /* Match the mobile nav background */
  }

  .nav-items-mobile .dropdown-content a {
    width: 100%; /* Full width for tap targets */
    padding: 1rem;
    text-align: center;
    border-bottom: 1px solid #333; /* Keep separation between items */
  }

  .nav-items-mobile .dropdown-open .dropdown-content,
  .nav-items-mobile .dropdown-content.show {
    display: flex; /* Show dropdown content when the state is 'open' */
  }

  .fade-background {
    display: block; /* Ensure the background fades when the mobile menu is open */
  }
}
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

.programs-wrapper {
  max-width: 800px;
  margin: auto;
  font-family: 'Montserrat', sans-serif;
  position: relative; /* Add position relative for breadcrumb positioning */
}

.breadcrumb {
  list-style-type: none; /* Remove list styling */
  padding: 0;
  margin: 20px 0; /* Add some top margin for spacing */
  display: flex; /* Align breadcrumb items in a row */
}

.breadcrumb-list {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.breadcrumb-item {
  margin-right: 10px;
}

.breadcrumb-item a {
  text-decoration: none;
  color: #000000;
  font-weight: 700;
}

.breadcrumb-item::after {
  content: '>';
  padding-left: 10px;
}

.breadcrumb-item:last-child::after {
  content: '';
}

.programs {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  padding: 50px 20px 20px; /* Adjust padding to give space for breadcrumb */
  background-color: #ffffff;
  /* border-radius: 15px; */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  margin-bottom: 6rem;
}


.programs-title {
  font-size: 2rem; /* Example font size */
  font-weight: 700; /* Montserrat bold */
  color: #333; /* Dark grey color for text */
  margin-bottom: 1rem; /* Space below title */
  margin-top: 3.5rem;
}

.programs-description {
  font-size: 1rem; /* Example font size */
  color: #666; /* Lighter grey color for text */
  margin-bottom: 2rem; /* Space below paragraph */
  padding: 3rem;
}

.programs-title {
  font-size: 2rem; /* Example font size */
  font-weight: 700; /* Montserrat bold */
  color: #333; /* Dark grey color for text */
  margin-bottom: 1rem; /* Space below title */
  text-transform: uppercase;
}

.programs-description {
  font-size: 1.25rem; /* Example font size */
  color: #666; /* Lighter grey color for text */
  margin-bottom: 2rem; /* Space below paragraph */
}

.program-link {
  display: block;
  width: 90%;
  margin: 1rem;
  padding: 2.5rem;
  background-color:  #e0d7bf; /* Default background color */
  /* border-radius: 10px; */
  text-align: center;
  text-decoration: none;
  color: #000000; /* Default text color */
  font-weight: bold;
  transition: background-color 0.3s ease, color 0.3s ease; /* Transition for background and text color */
  position: relative;
}

.program-link:hover {
  background-color: #000000; /* Background color on hover */
  color: #e0d7bf; /* Text color on hover */
}
.program-link:hover::before {
  left: 0;
}

.program-link span {
  position: relative;
  z-index: 1;
}

@media (max-width: 768px) {
  .programs-wrapper {
    max-width: 95vw;
  }

  .programs {
    padding: 15px;
  }

  .program-link {
    padding: 1.5rem;
  }
  .breadcrumb {
    position: static; /* Reset position for mobile */
    margin-bottom: 20px; /* Additional bottom margin for mobile */
  }

  .breadcrumb-list {
    justify-content: center; /* Center breadcrumb items on mobile */
  }

  .breadcrumb-item a {
    font-size: 0.9rem; /* Adjust font size for mobile */
  }
}

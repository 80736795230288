/* Styles for the Rental Property Calculator component */
.rental-property-calc {
  max-width: 800px; /* Set a maximum width for larger screens */
  margin: 0 auto; /* Center the calculator */
  padding: 20px;
  background-color: #f9f9f9; /* Light background color */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

/* Styles for the calculator widget */
#inch-calculator-icw {
  width: 100%; /* Make the widget take up the full width of its container */
  min-height: 800px; /* Set a minimum height */
}

/* Adjustments for smaller screens */
@media (max-width: 768px) {
  .rental-property-calc {
    padding: 10px;
    max-width: 100%; /* Allow the calculator to take up the full width on smaller screens */
  }

  #inch-calculator-icw {
    min-height: 600px; /* Adjust the height for smaller screens */
  }
}
